import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ApiCommunicationService {

  api_url: any = 'https://itscode.net/dev/strefadealera/api/public/';
  // api_url: any = 'http://localhost:8000/';
  random_str: any;

  constructor(private http: HttpClient) {
    this.random_str = this.makeid(10);
  }

  makeid(length) {
     var result           = '';
     var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
     var charactersLength = characters.length;
     for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
     }
     return result;
  }

  // LOGOWANIE
  loginUser(email:string, password:string){
    return this.http.post(this.api_url+'login',
    {
      email: email,
      password: password
    },
    {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'json'
    });
  }

  // REGISTER
  registerUser(email:string, name:string, nip:string, address:string, postcode:string, city:string, brand:number){
    return this.http.post(this.api_url+'register',
    {
      email: email,
      company_name: name,
      nip: nip,
      address: address,
      postcode: postcode,
      city: city,
      brand: brand
    },
    {
      headers: { 'Content-Type': 'application/json' },
      responseType: 'json'
    });
  }

  getBrands(){
    return this.http.get(this.api_url+'get_brands',
    {
      headers: { 'Content-Type': 'application/json'},
      responseType: 'json'
    });
  }

  getModels(id){
    return this.http.get(this.api_url+'get_models?brand_id='+id,
    {
      headers: { 'Content-Type': 'application/json'},
      responseType: 'json'
    });
  }

  getAccountDetails(api_token){
    return this.http.get(this.api_url+'get_account_details',
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token},
      responseType: 'json'
    });
  }

  saveBrand(selected_brand, name, api_token){
    return this.http.post(this.api_url+'edit_profile_brand',
    {
      brand: selected_brand,
      nickname: name
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token},
      responseType: 'json'
    });
  }

  saveContact(phone, email, website, api_token){
    return this.http.post(this.api_url+'edit_annoucement_contact_details',
    {
      phone: phone,
      announcement_email: email,
      website: website
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token},
      responseType: 'json'
    });
  }

  saveKey(otomoto_key, api_token){
    return this.http.post(this.api_url+'edit_otomoto_key',
    {
      otomoto_key: otomoto_key
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token},
      responseType: 'json'
    });
  }

  saveAccountDetails(company_name, nip, address, postcode, city, api_token){
    return this.http.post(this.api_url+'edit_company_details',
    {
      company_name: company_name,
      nip: nip,
      address: address,
      postcode: postcode,
      city: city
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token},
      responseType: 'json'
    });
  }

  saveAccountEmail(email, api_token){
    return this.http.post(this.api_url+'edit_account_email',
    {
      email: email
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token},
      responseType: 'json'
    });
  }

  savePassword(password, new_password, api_token){
    return this.http.post(this.api_url+'change_password',
    {
      password: password,
      new_password: new_password
    },
    {
      headers: { 'Content-Type': 'application/json', 'Authorization': api_token},
      responseType: 'json'
    });
  }

  updateProfilePicture(profilePicture, api_token){
    return this.http.post(this.api_url+'set_avatar',
    {
      avatar: profilePicture
    },
    {
      headers: { 'Content-Type': 'application/json',  'Authorization' :  api_token  },
      responseType: 'json'
    });
  }

  updateBannerPicture(bannerPicture, api_token){
    return this.http.post(this.api_url+'set_avatar',
    {
      banner: bannerPicture
    },
    {
      headers: { 'Content-Type': 'application/json',  'Authorization' :  api_token  },
      responseType: 'json'
    });
  }

  addAnnouncement(
    tytul,
    marka,
    model,
    seria,
    rodzaj,
    rok_produkcji,
    rodzaj_paliwa,
    moc,
    pojemnosc,
    liczba_drzwi,
    skrzynia_biegow,
    przebieg,
    typ,
    kolor,
    rodzaj_koloru,
    liczba_miejsc,
    naped,
    emisja,
    cena,
    opis,
    photos,
    api_token){
      return this.http.post(this.api_url+'add_announcement',
      {
        title: tytul,
        brand_id: marka,
        model_id: model,
        series_id: seria,
        announcement_type: rodzaj,
        year: rok_produkcji,
        fuel_type: rodzaj_paliwa,
        power: moc,
        engine_displacement: pojemnosc,
        number_of_doors: liczba_drzwi,
        car_gearbox: skrzynia_biegow,
        car_mileage: przebieg,
        type: typ,
        color: kolor,
        color_type: rodzaj_koloru,
        number_of_seats: liczba_miejsc,
        drive: naped,
        co2_emissions: emisja,
        price: cena,
        description: opis,
        photos: photos
      },
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': api_token},
        responseType: 'json'
      });
    }

    deleteAnnouncement(api_token, id){
      return this.http.post(this.api_url+'delete_announcement',
      {
        id: id
      },
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': api_token},
        responseType: 'json'
      });
    }

    editAnnouncement(
      id,
      tytul,
      marka,
      model,
      seria,
      rodzaj,
      rok_produkcji,
      rodzaj_paliwa,
      moc,
      pojemnosc,
      liczba_drzwi,
      skrzynia_biegow,
      przebieg,
      typ,
      kolor,
      rodzaj_koloru,
      liczba_miejsc,
      naped,
      emisja,
      cena,
      opis,
      api_token){
        return this.http.post(this.api_url+'edit_announcement',
        {
          id: id,
          title: tytul,
          brand_id: marka,
          model_id: model,
          series_id: seria,
          announcement_type: rodzaj,
          year: rok_produkcji,
          fuel_type: rodzaj_paliwa,
          power: moc,
          engine_displacement: pojemnosc,
          number_of_doors: liczba_drzwi,
          car_gearbox: skrzynia_biegow,
          car_mileage: przebieg,
          type: typ,
          color: kolor,
          color_type: rodzaj_koloru,
          number_of_seats: liczba_miejsc,
          drive: naped,
          co2_emissions: emisja,
          price: cena,
          description: opis
        },
        {
          headers: { 'Content-Type': 'application/json', 'Authorization': api_token},
          responseType: 'json'
        });
    }

    getAnnouncements(page_limit, page_index, star?, type?, dealer_id?, brand?, model?, generation?, year_from?, year_to?, fuel_type?){
      return this.http.post(this.api_url+'get_announcements',
      {
        limit: page_limit,
        index: page_index,
        star: star,
        announcement_type: type,
        dealer_id: dealer_id,
        brand: brand,
        model: model,
        generation: generation,
        year_from: year_from,
        year_to: year_to,
        fuel_type: fuel_type
      },
      {
        headers: { 'Content-Type': 'application/json'},
        responseType: 'json'
      });
    }

    getAnnouncementsByDealer(api_token){
      return this.http.get(this.api_url+'get_announcements_by_dealer',
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': api_token},
        responseType: 'json'
      });
    }

    setStarAnnounncement(id, api_token){
      return this.http.post(this.api_url+'set_announcements_stars',
      {
        id: id
      },
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': api_token},
        responseType: 'json'
      });
    }

    getDeactivatedDealers(api_token){
      return this.http.get(this.api_url+'get_deactivated_dealers',
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': api_token},
        responseType: 'json'
      });
    }

    getActivatedDealers(api_token){
      return this.http.get(this.api_url+'get_activated_dealers',
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': api_token},
        responseType: 'json'
      });
    }

    ActivateDealer(id, api_token){
      return this.http.post(this.api_url+'activate_dealer',
      {
        dealer_id: id
      },
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': api_token},
        responseType: 'json'
      });
    }

    DeleteDealer(id, api_token){
      return this.http.post(this.api_url+'delete_dealer',
      {
        dealer_id: id
      },
      {
        headers: { 'Content-Type': 'application/json', 'Authorization': api_token},
        responseType: 'json'
      });
    }

    getCar(id){
      return this.http.get(this.api_url+'get_announcement/'+id+'?str='+this.random_str,
      {
        headers: { 'Content-Type': 'application/json'},
        responseType: 'json'
      });
    }

    getGeolocalization(address, postcode, city){
      return this.http.get('https://geocode.search.hereapi.com/v1/geocode?q='+address+' '+postcode+' '+city+' Poland&apiKey=MTqimHVzvW7xEWYD9waMgI5qPHcqgzF9n0v8GaTA2eg',
      {

      });
    }
}
