import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { ApiCommunicationService } from '../api-communication.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-cars',
  templateUrl: './cars.component.html',
  styleUrls: ['./cars.component.scss']
})
export class CarsComponent implements OnInit {

  products: any;
  api_token: any;

  brands_api: any;
  brands: any;
  models: any;

  cars_api: any;
  cars: any;
  cars_count: any;

  search_count: any;

  loaded: any;
  page: any = 0;
  limit: any = 10;
  type: any;
  pageSizeOptions: number[] = [5, 10, 25, 100];

  dealer_id: any;

  selected: any = {
    brand: null,
    model: null,
    generation: null,
    year_from: null,
    year_to: null,
    price_from: null,
    price_to: null,
    number_of_seats: null,
    type: null,
    fuel_type: null,
    power_from: null,
    power_to: null,
    engine_displacement: null,
    number_of_doors: null,
    car_gearbox: null
  }

  years: any;

  constructor(private router: Router, private activeRoute: ActivatedRoute, private api: ApiCommunicationService, private snackBar: MatSnackBar) {
    this.activeRoute.queryParams.subscribe(params => {
      console.log(params);
      this.selected.brand = Number(params['brand']);
      this.selected.model = Number(params['model']);
      this.selected.generation = Number(params['generation']);
      this.selected.year_from = params['year_from'];
      this.selected.year_to = params['year_to'];
      this.selected.price_from = params['price_from'];
      this.selected.price_to = params['price_to'];
      this.selected.number_of_seats = params['number_of_seats'];
      this.selected.type = params['type'];
      this.selected.fuel_type = params['fuel_type'];
      this.selected.power_from = params['power_from'];
      this.selected.power_to = params['power_to'];
      this.selected.engine_displacement = params['engine_displacement'];
      this.selected.number_of_doors = params['number_of_doors'];
      this.selected.car_gearbox = params['car_gearbox'];
      console.log('param');
      console.log(this.selected.brand);
    });
  }

  ngOnInit() {
    this.getBrands();
    this.years = this.getYears();
    this.api_token = localStorage.getItem('api_token');
    this.type = this.activeRoute.snapshot.params['type'];
    if(this.type == 'wszystkie'){
      this.type = null;
    }
    this.dealer_id = this.activeRoute.snapshot.params['dealer_id'];
    this.getAnnouncements();
  }

  getYears(){
    var currentYear = new Date().getFullYear();
    var years = [];
    let startYear = 1900;
    while ( currentYear >= startYear ) {
        let year = currentYear--;
        years.push(''+year+'');
    }
    return years;
  }

  getBrands(){
    this.brands_api = this.api.getBrands();
    this.brands_api.subscribe(data => {
      this.brands = data.data;
      if(this.selected.brand != null){
        this.getModels(this.selected.brand);
      }
    }, err => {
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  getModels(id){
    this.brands_api = this.api.getModels(id);
    this.brands_api.subscribe(data => {
      this.models = data.data;
    }, err => {
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  getAnnouncements(){
    this.loaded = false;
    console.log(this.selected.brand);
    console.log(this.type);
    this.cars_api = this.api.getAnnouncements(this.limit, this.page, null, this.type, this.dealer_id, this.selected.brand, this.selected.model, this.selected.generation, this.selected.year_from, this.selected.year_to);
    this.cars_api.subscribe(data => {
      console.log(data);
      this.cars = data.data;
      this.cars_count = data.count;
      this.loaded = true;
    }, err => {

    });
  }

  getCount(){
    this.loaded = false;
    this.search_count = null;
    this.cars_api = this.api.getAnnouncements(100000000, 0, true, null, null, this.selected.brand, this.selected.model, this.selected.generation, this.selected.year_from, this.selected.year_to, null);
    this.cars_api.subscribe(data => {
      this.search_count = data['count'];
    }, err => {

    });
  }

  searchAnnouncements(){
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/cars'], { queryParams:
      {
        brand: this.selected.brand,
        model: this.selected.model,
        generation: this.selected.generation,
        year_from: this.selected.year_from,
        year_to: this.selected.year_to,
        price_from: this.selected.price_from,
        price_to: this.selected.price_to,
        number_of_seats: this.selected.number_of_seats,
        type: this.selected.type,
        fuel_type: this.selected.fuel_type,
        power_from: this.selected.power_from,
        power_to: this.selected.power_to,
        engine_displacement: this.selected.engine_displacement,
        number_of_doors: this.selected.number_of_doors,
        car_gearbox: this.selected.car_gearbox
      }
    });
  }

  getServerData(event){
    this.page = event.pageIndex;
    this.limit = event.pageSize;
    this.getAnnouncements();
  }

  showMessage(message){
    this.snackBar.open(message, 'OK', {
      duration: 2000,
    });
  }

}
