import { Injectable } from '@angular/core';
import {FormBuilder, FormGroup, FormControl} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormBuilderService {
  constructor(private formBuilder: FormBuilder){ }
  createForm(GivenForm: FormGroup, GivenObject:any):FormGroup {
    GivenForm = this.formBuilder.group(GivenObject);
    return GivenForm;
  }
  createFormwWithObjects(GivenForm: FormGroup, GivenObject:any):FormGroup {
    for (const property in GivenObject) {
     if(typeof GivenObject[property]=="object"){
      GivenObject[property]=this.formBuilder.group(GivenObject[property]);
     }
     else{
     GivenObject[property]=new FormControl('');
     }
    }
    GivenForm = this.formBuilder.group(GivenObject);
    return GivenForm;
  }
  // createFormwWithArrays(GivenForm: FormGroup, GivenObject:any):FormGroup{
  //   for (const property in GivenObject) {
  //     typeof GivenObject[property]!="undefined" ?  GivenObject[property]=new FormControl(GivenObject[property]) : GivenObject[property]=new FormControl('');
  //   }
  //   GivenForm = this.formBuilder.group(GivenObject);
  //   return GivenForm;
  // }
}
