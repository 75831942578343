import { Component, OnInit } from '@angular/core';
import { ApiCommunicationService } from '../api-communication.service';
import { Router, ActivatedRoute, ParamMap, Routes, RouterModule, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-car',
  templateUrl: './car.component.html',
  styleUrls: ['./car.component.scss']
})
export class CarComponent implements OnInit {

  api_token: any;
  id: any;
  car: any;
  loaded: any;
  car_api: any;
  show_dealer_contact: any;

  title = 'Angular Google Maps Example';
  lat: any;
  lng: any;

  selected: any;

  currentIndex: any = 0;

  constructor(private router: Router, private activeRoute: ActivatedRoute, private api: ApiCommunicationService) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
            return;
        }
        window.scrollTo(0, 0);
    });
    this.api_token = localStorage.getItem('api_token');
    this.id = this.activeRoute.snapshot.params['id'];
    this.getCar();
  }

  showDealerContact(){
    this.show_dealer_contact = !this.show_dealer_contact;
  }

  getCar(){
    this.loaded = false;
    this.car_api = this.api.getCar(this.id);
    this.car_api.subscribe(data => {
      console.log(data);
      this.car = data.data;
      this.loaded = true;
      this.getGeolocalization();
    }, err => {

    });
  }

  getGeolocalization(){
    this.car_api = this.api.getGeolocalization(this.car.dealer.address, this.car.dealer.postcode, this.car.dealer.city);
    this.car_api.subscribe(data => {
      console.log(data);
      let lat = data['items'][0]['position']['lat'];
      let lng = data['items'][0]['position']['lng'];
      if(lat != null && lng != null){
        this.lat = lat;
        this.lng = lng;
      }
    }, err => {

    });
  }

  allCars(type, dealer_id){
    console.log(type+' '+dealer_id);
    this.router.navigate(['/cars/'+type+'/'+dealer_id]);
  }

 slideConfig = {
   "slidesToShow": 1,
   "slidesToScroll": 1,
   "asNavFor": '.slider-nav',
   "arrows": true,
   "prevArrow": '<div class="prev_btn slick_btn"><img src="assets/imgs/left-arrow.svg" /></div>',
   "nextArrow": '<div class="next_btn slick_btn"><img src="assets/imgs/right-arrow.svg" /></div>'
 };

 slideNav = {
  "slidesToShow": 4,
  "slidesToScroll": 1,
  "asNavFor": '.carousel',
  "dots": false,
  "arrows": true,
  "prevArrow": '<div class="up_btn slick_btn"><img src="assets/imgs/up-arrow.svg" /></div>',
  "nextArrow": '<div class="down_btn slick_btn"><img src="assets/imgs/down-arrow.svg" /></div>',
  "focusOnSelect": true,
   "vertical": true,
   "verticalSwiping": true
 };

}
