import { Component, OnInit } from '@angular/core';
import { ApiCommunicationService } from '../api-communication.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute, ParamMap, Routes, RouterModule, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  questions: any;
  brands_api: any;
  brands: any;
  models: any;
  years: any;

  api_token: any;
  cars_api: any;
  cars: any;
  cars_count: any;
  cars_empty: any = [];

  search_count: any;

  electric_cars: any;
  electric_cars_count: any;
  electric_cars_empty: any = [];

  loaded: any;
  page: any = 0;
  limit: any = 4;

  selected: any = {
    brand: null,
    model: null,
    generation: null,
    year_from: null,
    year_to: null
  }

  slideConfig = {
    slidesToShow: 4,
    slidesToScroll: 4,
    infinite: true,
    autoplay: true,
    autplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  };

  constructor(private router: Router, private activeRoute: ActivatedRoute, public api: ApiCommunicationService, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.getBrands();
    this.years = this.getYears();
    this.getAnnouncements();
    this.getElectricAnnouncements();
  }

  searchCar(){
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/cars'], { queryParams:
      {
        brand: this.selected.brand,
        model: this.selected.model,
        generation: this.selected.generation,
        year_from: this.selected.year_from,
        year_to: this.selected.year_to
      }
    });
  }

  getYears(){
    var currentYear = new Date().getFullYear();
    var years = [];
    let startYear = 1900;
    while ( currentYear >= startYear ) {
        let year = currentYear--;
        years.push(''+year+'');
    }
    return years;
  }

  getBrands(){
    this.brands_api = this.api.getBrands();
    this.brands_api.subscribe(data => {
      this.brands = data.data;
      // console.log(data.data);
    }, err => {
      // this.loader.dismiss();
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  getModels(id){
    this.brands_api = this.api.getModels(id);
    this.brands_api.subscribe(data => {
      this.models = data.data;
      console.log(this.models);
      // console.log(data.data);
    }, err => {
      // this.loader.dismiss();
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  getCount(){
    this.loaded = false;
    this.search_count = null;
    console.log(this.selected);
    this.cars_api = this.api.getAnnouncements(100000000, 0, true, null, null, this.selected.brand, this.selected.model, this.selected.generation, this.selected.year_from, this.selected.year_to, null);
    this.cars_api.subscribe(data => {
      console.log('DATA:');
      console.log(data);
      this.search_count = data['count'];
    }, err => {

    });
  }

  getAnnouncements(){
    this.loaded = false;
    this.cars_api = this.api.getAnnouncements(this.limit, this.page, true, null, null, this.selected.brand, this.selected.model, this.selected.generation, this.selected.year_from, this.selected.year_to, null);
    this.cars_api.subscribe(data => {
      console.log(data);
      this.cars = data.data;
      this.cars_count = data.count;
      this.loaded = true;
      this.getCarsEmpty();
    }, err => {

    });
  }

  getElectricAnnouncements(){
    this.loaded = false;
    this.cars_api = this.api.getAnnouncements(this.limit, this.page, true, null, null, this.selected.brand, this.selected.model, this.selected.generation, this.selected.year_from, this.selected.year_to, 7);
    this.cars_api.subscribe(data => {
      console.log(data);
      this.electric_cars = data.data;
      this.electric_cars_count = data.count;
      this.loaded = true;
      this.getElectricCarsEmpty();
    }, err => {

    });
  }

  getCarsEmpty(){
    let loop = 4 - this.cars.length;
    for(let i = 0; i < loop; i++){
      this.cars_empty.push(i);
    }
  }
  getElectricCarsEmpty(){
    let loop = 4 - this.electric_cars.length;
    for(let i = 0; i < loop; i++){
      this.electric_cars_empty.push(i);
    }
  }

  showMessage(message){
    this.snackBar.open(message, 'OK', {
      duration: 2000,
    });
  }

}
