import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiCommunicationService } from '../api-communication.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';
import { PrivacyDialog } from '../app.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  checked = false;

  login_data: any = {
    email: '',
    password: ''
  }

  register_data: any = {
    email: '',
    name: '',
    nip: '',
    address: '',
    postcode: '',
    city: ''
  }

  user_api: any;
  api_token: any;
  brands_api: any;
  brands: any;
  selected_brand: any;

  constructor(public api: ApiCommunicationService, private router: Router, private snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    this.api_token = localStorage.getItem('api_token');
    if(this.api_token != null){
      this.router.navigate(['/panel']);
    }
    this.getBrands();
  }

  getBrands(){
    this.brands_api = this.api.getBrands();
    this.brands_api.subscribe(data => {
      this.brands = data.data;
      console.log(data.data);
    }, err => {
      // this.loader.dismiss();
      this.showError('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  login(){
    if(this.login_data.email == null || this.login_data.password == null || this.login_data.email == '' || this.login_data.password == ''){
      this.showError('Prosimy uzupełnić wszystkie pola.');
    }else{
      this.user_api = this.api.loginUser(this.login_data.email, this.login_data.password);
      this.user_api.subscribe(data => {
        if(data.status == false){
          this.showError(data.message);
        }else if(data.status == true){
          localStorage.setItem('api_token', data.api_token);
          localStorage.setItem('company_name', data.data.company_name);
          localStorage.setItem('agreement_status', data.data.agreement_status);
          localStorage.setItem('activated', data.data.activated);
          localStorage.setItem('email', data.data.email);
          if(data.data.avatar_url == null){
            localStorage.setItem('avatar_url', '/assets/imgs/default_avatar.jpg');
          }else{
            localStorage.setItem('avatar_url', data.data.avatar_url);
          }
          this.router.navigate(['/panel']);
        }
      }, err => {
        // this.loader.dismiss();
        this.showError('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z nami.');
      });
    }
  }

  register(){
    if(this.register_data.email == null || this.register_data.name == null || this.register_data.nip == null || this.register_data.address == null || this.register_data.postcode == null || this.register_data.city == null || this.register_data.email == '' || this.register_data.name == '' || this.register_data.nip == null || this.register_data.address == '' || this.register_data.postcode == '' || this.register_data.city == '' || this.selected_brand == null || this.selected_brand == ''){
      this.showError('Prosimy uzupełnić wszystkie pola.');
    }else{
      if(this.checked == true){
        this.user_api = this.api.registerUser(this.register_data.email, this.register_data.name, this.register_data.nip, this.register_data.address, this.register_data.postcode, this.register_data.city, this.selected_brand);
        this.user_api.subscribe(data => {
          console.log(data);
          if(data.status == false){
            this.showError(data.message);
          }else if(data.status == true){
            this.register_data = {
              email: '',
              name: '',
              nip: '',
              address: '',
              postcode: '',
              city: ''
            }
            this.selected_brand = null;
            this.showError('Pomyślnie zarejestrowano konto. Sprawdź skrzynkę mailową.');
          }
        }, err => {
          // this.loader.dismiss();
          if(err.error.email != null){
            this.showError(err.error.email[0]);
          }else{
            this.showError('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z nami.');
          }
        });
      }else{
        this.showError('Prosimy zaakceptować politykę prywatności');
      }
    }
  }

  showError(message){
    this.snackBar.open(message, 'OK', {
      duration: 2000,
    });
  }

  openPrivacy() {
    const dialogRef = this.dialog.open(PrivacyDialog);

    dialogRef.afterClosed().subscribe(result => {

    });
  }
}
