import { Component, OnInit, ViewChild, ElementRef, Inject, Input,Output,EventEmitter } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, Routes, RouterModule } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApiCommunicationService } from '../api-communication.service';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrivacyDialog, TermsDialog } from '../app.component';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { FormBuilderService } from '../form_service/form-builder.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})

export class PanelComponent implements OnInit {

  page: any = 'ogloszenia';
  agreement_status: any;
  avatar_url: any;
  company_name: any;
  phone: any;
  email: any;
  api_token: any;
  account_api: any;
  activated: any;

  constructor(private activatedRoute: ActivatedRoute, private api: ApiCommunicationService, private snackBar: MatSnackBar, private router: Router, public dialog: MatDialog) {
    this.activatedRoute.queryParams.subscribe(params => {
        let menu = params['menu'];
        if(menu != null && menu != ''){
          this.page = menu;
        }
    });
  }

  ngOnInit() {
    this.agreement_status = localStorage.getItem('agreement_status');
    this.avatar_url = localStorage.getItem('avatar_url');
    this.company_name = localStorage.getItem('company_name');
    this.api_token = localStorage.getItem('api_token');
    this.activated = localStorage.getItem('activated');
    this.email = localStorage.getItem('email');
    this.getAccountDetails();
  }

  setPage(page){
    this.page = page;
  }

  agreementAccept(){
    this.agreement_status = "1";
    localStorage.setItem('agreement_status', "1");
    //USTAWIENIE NA SERWERZE PARAMETRU W PROFILU
  }

  getAccountDetails(){
    this.account_api = this.api.getAccountDetails(this.api_token);
    this.account_api.subscribe(data => {
      this.activated = data.data.activated;
      localStorage.setItem('activated', this.activated);
    }, err => {
      // this.loader.dismiss();
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  showMessage(message){
    this.snackBar.open(message, 'OK', {
      duration: 2000,
    });
  }

  logout(){
    localStorage.clear();
    this.router.navigate(['/login']);
  }

}

@Component({
  selector: 'app-list',
  templateUrl: './pages/list.html',
  styleUrls: ['./pages/style.scss']
})

export class PanelList {

  api_token: any;
  announcements_api: any;
  announcements: any;

  page_limit: any = 1000;
  page_index: any = 0;

  constructor(public api: ApiCommunicationService, private snackBar: MatSnackBar, private router: Router) { }

  ngOnInit(){
    this.api_token = localStorage.getItem('api_token');
    this.getAnnouncements();
  }

  getAnnouncements(){
    this.announcements_api = this.api.getAnnouncementsByDealer(this.api_token);
    this.announcements_api.subscribe(data => {
      this.announcements = data.data;
      console.log(data.data);
    }, err => {
      // this.loader.dismiss();
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  showMessage(message){
    this.snackBar.open(message, 'OK', {
      duration: 2000,
    });
  }

  edit(id){
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/panel'], { queryParams: { menu: 'edit', id: id } });
  }

  star(id){
    this.announcements_api = this.api.setStarAnnounncement(id, this.api_token);
    this.announcements_api.subscribe(data => {
      console.log(id);
      this.getAnnouncements();
    }, err => {
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

}

@Component({
  selector: 'app-new',
  templateUrl: './pages/new.html',
  styleUrls: ['./pages/style.scss']
})

export class PanelNew {

  years: any = [];
  api_token: any;
  announcement_api: any;
  brands_api: any;
  brands: any;
  models: any;

  tytul: any;
  marka: any;
  model: any;
  seria: any;
  rodzaj: any = '';
  rok_produkcji: any = '';
  rodzaj_paliwa: any = '';
  moc: any = null;
  pojemnosc: any = '';
  liczba_drzwi: any = '';
  skrzynia_biegow: any = '';
  przebieg: any = '';
  typ: any = '';
  kolor: any = '';
  rodzaj_koloru: any = '';
  liczba_miejsc: any = '';
  naped: any = '';
  emisja: any = '';
  cena: any = '';
  opis: any = '';

  account_api: any;
  activated: any;


  constructor(public api: ApiCommunicationService, private snackBar: MatSnackBar, private router: Router) { }

  ngOnInit(){
    // let current_year = 2020;
    // for (let n = 1900; n < current_year; ++n) {
    //   years.push(n);
    // }
    this.getBrands();
    this.years = this.getYears();
    this.api_token = localStorage.getItem('api_token');
    // console.log(this.years);

    this.activated = localStorage.getItem('activated');
    this.getAccountDetails();
  }

  files: File[] = [];
  files_base64: any = [];


  getAccountDetails(){
    this.account_api = this.api.getAccountDetails(this.api_token);
    this.account_api.subscribe(data => {
      this.activated = data.data.activated;
      localStorage.setItem('activated', this.activated);
    }, err => {
      // this.loader.dismiss();
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  getYears(){
    var currentYear = new Date().getFullYear();
    var years = [];
    let startYear = 1900;
    while ( currentYear >= startYear ) {
        let year = currentYear--;
        years.push(''+year+'');
    }
    return years;
  }

  getBrands(){
    this.brands_api = this.api.getBrands();
    this.brands_api.subscribe(data => {
      this.brands = data.data;
      // console.log(data.data);
    }, err => {
      // this.loader.dismiss();
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  getModels(id){
    this.brands_api = this.api.getModels(id);
    this.brands_api.subscribe(data => {
      this.models = data.data;
      console.log(this.models);
      // console.log(data.data);
    }, err => {
      // this.loader.dismiss();
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  addAnnouncement(){
    if(this.tytul == '' || this.tytul == null ||
    this.marka == '' || this.marka == null ||
    this.rodzaj == '' || this.rodzaj == null ||
    this.rok_produkcji == '' || this.rok_produkcji == null ||
    this.rodzaj_paliwa == '' || this.rodzaj_paliwa == null ||
    this.pojemnosc == '' || this.pojemnosc == null ||
    this.liczba_drzwi == '' || this.liczba_drzwi == null ||
    this.skrzynia_biegow == '' || this.skrzynia_biegow == null ||
    this.typ == '' || this.typ == null ||
    this.kolor == '' || this.kolor == null ||
    this.rodzaj_koloru == '' || this.rodzaj_koloru == null ||
    this.liczba_miejsc == '' || this.liczba_miejsc == null ||
    this.naped == '' || this.naped == null ||
    this.emisja == '' || this.emisja == null ||
    this.cena == '' || this.cena == null){
      this.showMessage('Prosimy wypełnić wszystkie wymagane pola.');
    }else{
      this.announcement_api = this.api.addAnnouncement(
        this.tytul,
        this.marka,
        this.model,
        this.seria,
        this.rodzaj,
        this.rok_produkcji,
        this.rodzaj_paliwa,
        this.moc,
        this.pojemnosc,
        this.liczba_drzwi,
        this.skrzynia_biegow,
        this.przebieg,
        this.typ,
        this.kolor,
        this.rodzaj_koloru,
        this.liczba_miejsc,
        this.naped,
        this.emisja,
        this.cena,
        this.opis,
        this.files_base64,
        this.api_token);
      this.announcement_api.subscribe(data => {
        this.showMessage('Pomyślnie dodano ogłoszenie');
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/panel'], { queryParams: { menu: 'ogloszenia' } });
      }, err => {
        // this.loader.dismiss();
        console.log(err);
        this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
      });
    }
  }

  showMessage(message){
    this.snackBar.open(message, 'OK', {
      duration: 2000,
    });
  }

  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);

    let n=0;
    this.files.forEach(function (value) {
      console.log('PHOTO'+n);
      this.readFileFunction(n);
      n++;
    }.bind(this));
  }

  readFileFunction(n){
    this.readFile(this.files[n]).then(fileContents => {
      console.log(fileContents);
      this.files_base64.push(fileContents);
    });
  }

  private async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        return resolve((e.target as FileReader).result);
      };

      reader.onerror = e => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };

      if (!file) {
        console.error('No file to read.');
        return reject(null);
      }

      reader.readAsDataURL(file);
    });
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
    this.files_base64.splice(this.files.indexOf(event), 1);
    console.log(this.files_base64);
  }

}

@Component({
  selector: 'app-edit',
  templateUrl: './pages/edit.html',
  styleUrls: ['./pages/style.scss']
})

export class PanelEdit {

  years: any = [];
  api_token: any;
  car_api: any;
  announcement_api: any;
  brands_api: any;
  brands: any;
  models: any;

  tytul: any;
  marka: any;
  model: any;
  seria: any;
  rodzaj: any = '';
  rok_produkcji: any = '';
  rodzaj_paliwa: any = '';
  moc: any = '';
  pojemnosc: any = '';
  liczba_drzwi: any = '';
  skrzynia_biegow: any = '';
  przebieg: any = '';
  typ: any = '';
  kolor: any = '';
  rodzaj_koloru: any = '';
  liczba_miejsc: any = '';
  naped: any = '';
  emisja: any = '';
  cena: any = '';
  opis: any = '';

  account_api: any;
  activated: any;

  id: any;
  loaded: any = false;

  constructor(public api: ApiCommunicationService, private snackBar: MatSnackBar, private router: Router, private activatedRoute: ActivatedRoute, public dialog: MatDialog) {
    this.activatedRoute.queryParams.subscribe(params => {
        let id = params['id'];
        if(id != null && id != ''){
          this.id = id;
          this.getCar();
        }else{
          this.redirectToHome();
        }
    });
  }

  redirectToHome(){
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/panel'], { queryParams: { menu: 'ogloszenia' } });
  }

  getCar(){
    this.loaded = false;
    this.car_api = this.api.getCar(this.id);
    this.car_api.subscribe(data => {
      console.log(data);
      this.loaded = true;
      this.getModels(data.data.car_brand.id);
      this.tytul = data.data.title;
      this.marka = data.data.car_brand.id;
      if(data.data.car_model != null){
        this.model = data.data.car_model.id;
      }
      // this.seria = ;
      this.rodzaj = data.data.announcement_type;
      this.rok_produkcji = data.data.year;
      this.rodzaj_paliwa = ''+data.data.fuel_type+'';
      this.moc = data.data.power;
      this.pojemnosc = data.data.engine_displacement;
      this.liczba_drzwi = ''+data.data.number_of_doors+'';
      this.skrzynia_biegow = ''+data.data.car_gearbox+'';
      this.przebieg = data.data.car_mileage;
      this.typ = ''+data.data.type+'';
      this.kolor = ''+data.data.color+'';
      this.rodzaj_koloru = ''+data.data.color_type+'';
      this.liczba_miejsc = ''+data.data.number_of_seats+'';
      this.naped = ''+data.data.drive+'';
      this.emisja = data.data.co2_emissions;
      this.cena = data.data.price;
      this.opis = data.data.description;
    }, err => {
      this.redirectToHome();
    });
  }

  ngOnInit(){
    // let current_year = 2020;
    // for (let n = 1900; n < current_year; ++n) {
    //   years.push(n);
    // }
    this.getBrands();
    this.years = this.getYears();
    this.api_token = localStorage.getItem('api_token');
    console.log(this.api_token);
    // console.log(this.years);

    this.activated = localStorage.getItem('activated');
    this.getAccountDetails();
  }

  files: File[] = [];
  files_base64: any = [];


  getAccountDetails(){
    this.account_api = this.api.getAccountDetails(this.api_token);
    this.account_api.subscribe(data => {
      this.activated = data.data.activated;
      localStorage.setItem('activated', this.activated);
    }, err => {
      // this.loader.dismiss();
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  getYears(){
    var currentYear = new Date().getFullYear();
    var years = [];
    let startYear = 1900;
    while ( currentYear >= startYear ) {
        let year = currentYear--;
        years.push(''+year+'');
    }
    return years;
  }

  getBrands(){
    this.brands_api = this.api.getBrands();
    this.brands_api.subscribe(data => {
      this.brands = data.data;
      // console.log(data.data);
    }, err => {
      // this.loader.dismiss();
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  getModels(id){
    this.brands_api = this.api.getModels(id);
    this.brands_api.subscribe(data => {
      this.models = data.data;
      console.log(this.models);
      // console.log(data.data);
    }, err => {
      // this.loader.dismiss();
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  editAnnouncement(){
    if(this.tytul == '' || this.tytul == null ||
    this.marka == '' || this.marka == null ||
    this.rodzaj == '' || this.rodzaj == null ||
    this.rok_produkcji == '' || this.rok_produkcji == null ||
    this.rodzaj_paliwa == '' || this.rodzaj_paliwa == null ||
    this.pojemnosc == '' || this.pojemnosc == null ||
    this.liczba_drzwi == '' || this.liczba_drzwi == null ||
    this.skrzynia_biegow == '' || this.skrzynia_biegow == null ||
    this.typ == '' || this.typ == null ||
    this.kolor == '' || this.kolor == null ||
    this.rodzaj_koloru == '' || this.rodzaj_koloru == null ||
    this.liczba_miejsc == '' || this.liczba_miejsc == null ||
    this.naped == '' || this.naped == null ||
    this.emisja == '' || this.emisja == null ||
    this.cena == '' || this.cena == null){
      this.showMessage('Prosimy wypełnić wszystkie wymagane pola.');
    }else{
      this.announcement_api = this.api.editAnnouncement(
        this.id,
        this.tytul,
        this.marka,
        this.model,
        this.seria,
        this.rodzaj,
        this.rok_produkcji,
        this.rodzaj_paliwa,
        this.moc,
        this.pojemnosc,
        this.liczba_drzwi,
        this.skrzynia_biegow,
        this.przebieg,
        this.typ,
        this.kolor,
        this.rodzaj_koloru,
        this.liczba_miejsc,
        this.naped,
        this.emisja,
        this.cena,
        this.opis,
        this.api_token);
      this.announcement_api.subscribe(data => {
        this.showMessage('Pomyślnie zaktualizowano ogłoszenie');
        this.redirectToHome();
      }, err => {
        // this.loader.dismiss();
        console.log(err);
        this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
      });
    }
  }

  showMessage(message){
    this.snackBar.open(message, 'OK', {
      duration: 2000,
    });
  }

  onSelect(event) {
    console.log(event);
    this.files.push(...event.addedFiles);

    let n=0;
    this.files.forEach(function (value) {
      console.log('PHOTO'+n);
      this.readFileFunction(n);
      n++;
    }.bind(this));
  }

  readFileFunction(n){
    this.readFile(this.files[n]).then(fileContents => {
      console.log(fileContents);
      this.files_base64.push(fileContents);
    });
  }

  private async readFile(file: File): Promise<string | ArrayBuffer> {
    return new Promise<string | ArrayBuffer>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = e => {
        return resolve((e.target as FileReader).result);
      };

      reader.onerror = e => {
        console.error(`FileReader failed on file ${file.name}.`);
        return reject(null);
      };

      if (!file) {
        console.error('No file to read.');
        return reject(null);
      }

      reader.readAsDataURL(file);
    });
  }

  onRemove(event) {
    console.log(event);
    this.files.splice(this.files.indexOf(event), 1);
    this.files_base64.splice(this.files.indexOf(event), 1);
    console.log(this.files_base64);
  }

  deleteAnnouncement(){
    const dialogRef = this.dialog.open(AnnouncementDeleteConfirmDialog, {
      width: '400px',
      data: { id: this.id, api_token: this.api_token },
    });
    dialogRef.afterClosed().subscribe(result => {

    });
  }
}

@Component({
  selector: 'announcement-delete-confirm-dialog',
  templateUrl: './dialogs/announcement-delete.html',
})
export class AnnouncementDeleteConfirmDialog {
  delete_loader: any = 0;
  annoucements_api: any;

  constructor(public dialogRef: MatDialogRef<AnnouncementDeleteConfirmDialog>, @Inject(MAT_DIALOG_DATA) public data: {id: any, api_token: any}, private router: Router, private snackBar: MatSnackBar, private api: ApiCommunicationService) { }

  confirmDelete(){
    console.log(this.data.api_token+' '+this.data.id);
    this.delete_loader = 1;
    this.annoucements_api = this.api.deleteAnnouncement(this.data.api_token, this.data.id);
    this.annoucements_api.subscribe(data => {
      this.delete_loader = 0;
      if(data.status == true){
        this.dialogRef.close();
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        }
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['/panel'], { queryParams: { menu: 'ogloszenia' } });

        this.showMessage('Usunięto ogłoszenie.');
      }else{
        this.showMessage('Wystąpił nieznany błąd.');
      }
    }, err => {
      this.delete_loader = 0;
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  showMessage(message){
    this.snackBar.open(message, 'OK', {
      duration: 2000,
    });
  }
}

@Component({
  selector: 'app-profile',
  templateUrl: './pages/profile.html',
  styleUrls: ['./pages/style.scss']
})

export class PanelProfile {

  geo_api: any;
  brands_api: any;
  user_api: any;
  avatar_url: any;
  banner_url: any;
  api_token: any;
  brands: any;
  selected_brand: any;
  selectedBrandString: any;
  name: any;

  phone: any;
  email: any;
  website: any;
  nip: any;
  address: any;
  postcode: any;
  city: any;

  lat: any;
  lng: any;

  activated: any;

  profilePicturesDirectoryPath:any ="https://itscode.net/dev/strefadealera/api/storage/app/avatars/";
  profilePictureForm: FormGroup= new FormGroup({
    profilePicture: new FormControl()
  });
  profilePictureSrc: any;
  currentProfilePictureSrc: any;

  bannerPicturesDirectoryPath:any ="https://itscode.net/dev/strefadealera/api/storage/app/banners/";
  bannerPictureForm: FormGroup= new FormGroup({
    bannerPicture: new FormControl()
  });
  bannerPictureSrc: any;
  currentBannerPictureSrc: any;


  otomoto_key: any;

  @ViewChild("selectedBrand", { static: false }) public selectedBrand: ElementRef;

  constructor(public api: ApiCommunicationService, private snackBar: MatSnackBar, private formBuilderService: FormBuilderService, private formBuilder: FormBuilder) { }

  ngOnInit(){
    console.log('test');
    this.api_token = localStorage.getItem('api_token');
    this.avatar_url = localStorage.getItem('avatar_url');
    // this.banner_url = localStorage.getItem('banner_url');
    this.banner_url = 'assets/imgs/no_photo.png';
    this.getBrands();
    this.getAccountDetails();
    localStorage.getItem('activated');
  }

  manageProfilePicture(event){
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        this.profilePictureSrc = reader.result;
      };
      reader.readAsDataURL(file);
      console.log(event);
    }
  }
  cancelChosenProfilePicture(){
    this.profilePictureSrc=null;
    this.profilePictureForm.reset();
  }
  changeProfilePicture(){
    this.user_api = this.api.updateProfilePicture(this.profilePictureSrc, this.api_token);
    this.user_api.subscribe(data => {
      // console.log(res);
      // if(res['status']){
      //
        this.profilePictureSrc=null;
        this.profilePictureForm.reset();
      //
      //   // localStorage.setItem('avatar_url',res['data']);
      //   this.currentProfilePictureSrc=this.profilePicturesDirectoryPath+res['data'];
      // }
    });
  }


  manageBannerPicture(event){
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.onload = e => {
        this.bannerPictureSrc = reader.result;
      };
      reader.readAsDataURL(file);
      console.log(event);
    }
  }
  cancelChosenBannerPicture(){
    this.bannerPictureSrc=null;
    this.bannerPictureForm.reset();
  }
  changeBannerPicture(){
    this.user_api = this.api.updateBannerPicture(this.bannerPictureSrc, this.api_token);
    this.user_api.subscribe(data => {
      // console.log(res);
      // if(res['status']){
      //
        this.bannerPictureSrc=null;
        this.bannerPictureForm.reset();
      //
      //   // localStorage.setItem('avatar_url',res['data']);
      //   this.currentProfilePictureSrc=this.profilePicturesDirectoryPath+res['data'];
      // }
    });
  }

  getAccountDetails(){
    this.user_api = this.api.getAccountDetails(this.api_token);
    this.user_api.subscribe(data => {
      console.log(data);
      this.name = data.data.nickname;
      this.email = data.data.announcement_email;
      this.website = data.data.website;
      this.phone = data.data.phone;
      this.nip = data.data.nip;
      this.address = data.data.address;
      this.postcode = data.data.postcode;
      this.city = data.data.city;
      this.selected_brand = Number(data.data.brand.id);
      this.selectedBrandString = data.data.brand.name;
      this.otomoto_key = data.data.otomoto_key;

      this.activated = data.data.activated;
      localStorage.setItem('activated', this.activated);

      this.getGeolocalization();
    }, err => {
      // this.loader.dismiss();
      console.log('ERROR:');
      console.log(err);
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  getGeolocalization(){
    this.geo_api = this.api.getGeolocalization(this.address, this.postcode, this.city);
    this.geo_api.subscribe(data => {
      console.log(data);
      let lat = data['items'][0]['position']['lat'];
      let lng = data['items'][0]['position']['lng'];
      if(lat != null && lng != null){
        this.lat = lat;
        this.lng = lng;
      }
    }, err => {

    });
  }

  getBrands(){
    this.brands_api = this.api.getBrands();
    this.brands_api.subscribe(data => {
      this.brands = data.data;
      // console.log(data.data);
    }, err => {
      // this.loader.dismiss();
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  selectBrand(event){
    this.selectedBrandString = event.source.selected.id;
  }

  showMessage(message){
    this.snackBar.open(message, 'OK', {
      duration: 2000,
    });
  }


  saveBrand(){
    this.brands_api = this.api.saveBrand(this.selected_brand, this.name, this.api_token);
    this.brands_api.subscribe(data => {
      this.getAccountDetails();
      this.showMessage('Pomyślnie zapisano zmiany');
    }, err => {
      // this.loader.dismiss();
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  saveContact(){
    if(this.email == null || this.email == ''){
      this.showMessage('Pole email nie może być puste');
    }else{
      this.brands_api = this.api.saveContact(this.phone, this.email, this.website, this.api_token);
      this.brands_api.subscribe(data => {
        this.getAccountDetails();
        this.showMessage('Pomyślnie zapisano zmiany');
      }, err => {
        // this.loader.dismiss();
        this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
      });
    }
  }

  saveKey(){
    if(this.otomoto_key == null || this.otomoto_key == ''){
      this.showMessage('Pole klucz API nie może być puste');
    }else{
      this.brands_api = this.api.saveKey(this.otomoto_key, this.api_token);
      this.brands_api.subscribe(data => {
        this.getAccountDetails();
        this.showMessage('Pomyślnie zapisano zmiany');
      }, err => {
        // this.loader.dismiss();
        this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
      });
    }
  }

}

@Component({
  selector: 'app-settings',
  templateUrl: './pages/settings.html',
  styleUrls: ['./pages/style.scss']
})

export class PanelSettings {

  api_token: any;
  user_api: any;

  settings_data: any = {
    company_name: '',
    nip: '',
    address: '',
    postcode: '',
    city: ''
  };

  settings_email: any = '';
  current_password: any = '';
  new_password: any = '';
  repeat_new_password: any = '';
  activated: any;

  constructor(public api: ApiCommunicationService, private snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit(){
    this.api_token = localStorage.getItem('api_token');
    this.getAccountDetails();
    this.activated = localStorage.getItem('activated');
  }

  getAccountDetails(){
    this.user_api = this.api.getAccountDetails(this.api_token);
    this.user_api.subscribe(data => {
      this.settings_data.company_name = data.data.company_name;
      this.settings_data.nip = data.data.nip;
      this.settings_data.address = data.data.address;
      this.settings_data.postcode = data.data.postcode;
      this.settings_data.city = data.data.city;
      this.settings_email = data.data.email;

      this.activated = data.data.activated;
      localStorage.setItem('activated', this.activated);
    }, err => {
      // this.loader.dismiss();
      console.log('ERROR:');
      console.log(err);
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  saveAccountDetails(){
    if(this.settings_data.company_name == null || this.settings_data.company_name == ''
      || this.settings_data.nip == null || this.settings_data.nip == ''
      || this.settings_data.address == null || this.settings_data.address == ''
      || this.settings_data.postcode == null || this.settings_data.postcode == ''
      || this.settings_data.city == null || this.settings_data.city == ''){
      this.showMessage('Prosimy wypełnić wszystkie pola');
    }else{
      this.user_api = this.api.saveAccountDetails(this.settings_data.company_name, this.settings_data.nip, this.settings_data.address, this.settings_data.postcode, this.settings_data.city, this.api_token);
      this.user_api.subscribe(data => {
        this.getAccountDetails();
        this.showMessage('Pomyślnie zapisano zmiany');
      }, err => {
        // this.loader.dismiss();
        this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
      });
    }
  }

  saveAccountEmail(){
    if(this.settings_email == null || this.settings_email == ''){
      this.showMessage('Pole email nie może być puste');
    }else{
      this.user_api = this.api.saveAccountEmail(this.settings_email, this.api_token);
      this.user_api.subscribe(data => {
        this.getAccountDetails();
        this.showMessage('Pomyślnie zapisano zmiany');
      }, err => {
        if(err.error.email != null){
          this.showMessage(err.error.email[0]);
        }else{
          this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z nami.');
        }
      });
    }
  }

  saveNewPassword(){
    if(this.current_password == null || this.current_password == ''
      || this.new_password == null || this.new_password == ''
      || this.repeat_new_password == null || this.repeat_new_password == ''){
      this.showMessage('Prosimy wypełnić wszystkie pola');
    }else if(this.new_password != this.repeat_new_password){
      this.showMessage('Podane hasła nie zgadzają się');
    }else{
      this.user_api = this.api.savePassword(this.current_password, this.new_password, this.api_token);
      this.user_api.subscribe(data => {
        this.current_password = '';
        this.new_password = '';
        this.repeat_new_password = '';
        this.showMessage('Pomyślnie zapisano zmiany');
      }, err => {
        if(err.error.email != null){
          this.showMessage(err.error.email[0]);
        }else{
          this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z nami.');
        }
      });
    }
  }

  showMessage(message){
    this.snackBar.open(message, 'OK', {
      duration: 2000,
    });
  }

  openPrivacy() {
    const dialogRef = this.dialog.open(PrivacyDialog);

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openTerms() {
    const dialogRef = this.dialog.open(TermsDialog);

    dialogRef.afterClosed().subscribe(result => {

    });
  }

}




@Component({
  selector: 'app-dealers',
  templateUrl: './pages/dealers.html',
  styleUrls: ['./pages/style.scss']
})

export class PanelDealers {

  api_token: any;
  user_api: any;

  settings_email: any = '';
  current_password: any = '';
  new_password: any = '';
  repeat_new_password: any = '';
  activated: any;

  activated_dealers: any;
  deactivated_dealers: any;

  constructor(public api: ApiCommunicationService, private snackBar: MatSnackBar) { }

  ngOnInit(){
    this.api_token = localStorage.getItem('api_token');
    this.activated = localStorage.getItem('activated');
    this.getAccountDetails();
    this.getDeactivatedDealers();
    this.getActivatedDealers();
  }

  getAccountDetails(){
    this.user_api = this.api.getAccountDetails(this.api_token);
    this.user_api.subscribe(data => {
      this.activated = data.data.activated;
      localStorage.setItem('activated', this.activated);
    }, err => {
      // this.loader.dismiss();
      console.log('ERROR:');
      console.log(err);
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  getDeactivatedDealers(){
    this.user_api = this.api.getDeactivatedDealers(this.api_token);
    this.user_api.subscribe(data => {
      console.log('DEACTIVATED DEALERS');
      console.log(data.data);
      this.deactivated_dealers = data.data;
    }, err => {
      // this.loader.dismiss();
      console.log('ERROR:');
      console.log(err);
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  getActivatedDealers(){
    this.user_api = this.api.getActivatedDealers(this.api_token);
    this.user_api.subscribe(data => {
      console.log('ACTIVATED DEALERS');
      console.log(data.data);
      this.activated_dealers = data.data;
    }, err => {
      // this.loader.dismiss();
      console.log('ERROR:');
      console.log(err);
      this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
    });
  }

  activateDealer(id){
    if(confirm("Na pewno chcesz AKTYWOWAĆ tego dealera?")) {
      this.user_api = this.api.ActivateDealer(id, this.api_token);
      this.user_api.subscribe(data => {
        this.getDeactivatedDealers();
      }, err => {
        // this.loader.dismiss();
        console.log('ERROR:');
        console.log(err);
        this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
      });
    }
  }

  deleteDealer(id){
    if(confirm("Na pewno chcesz USUNĄĆ tego dealera?")) {
      this.user_api = this.api.DeleteDealer(id, this.api_token);
      this.user_api.subscribe(data => {
        this.getDeactivatedDealers();
      }, err => {
        // this.loader.dismiss();
        console.log('ERROR:');
        console.log(err);
        this.showMessage('Wystąpił błąd połączenia z serwerem, prosimy spróbować ponownie lub skontaktować się z działem technicznym.');
      });
    }
  }

  showMessage(message){
    this.snackBar.open(message, 'OK', {
      duration: 2000,
    });
  }

}
