import { Component } from '@angular/core';
import { Location } from "@angular/common";
import { Router, ActivatedRoute, ParamMap, Routes, RouterModule } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  api_token: any;
  login_status: any;
  current_route: any = null;
  company_name: any;
  avatar_url: any;
  currentYear: any;

  constructor(private router: Router, location: Location, public dialog: MatDialog) {
    router.events.subscribe(val => {
      this.company_name = localStorage.getItem('company_name');
      this.api_token = localStorage.getItem('api_token');
      this.avatar_url = localStorage.getItem('avatar_url');
      let route = location.path().split("?", 2)[0];
      if(this.current_route != route){
        this.current_route = route;
        console.log(route);
        this.redirect_to_login();
      }
    });

    this.currentYear = new Date().getFullYear();
  }

  redirect_to_login(){
    this.api_token = localStorage.getItem('api_token');
    if(this.api_token == null){
      this.login_status = 0;
      if(this.current_route == '/panel'){
        this.router.navigate(['/login']);
      }
    }else{
      this.login_status = 1;
    }
  }

  menuRedirect(link){
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
    }
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([link]);
  }

  openPricing() {
    const dialogRef = this.dialog.open(PricingDialog);

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openTerms() {
    const dialogRef = this.dialog.open(TermsDialog);

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openPrivacy() {
    const dialogRef = this.dialog.open(PrivacyDialog);

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openCookies() {
    const dialogRef = this.dialog.open(CookiesDialog);

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openRodo() {
    const dialogRef = this.dialog.open(RodoDialog);

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  openContact() {
    const dialogRef = this.dialog.open(ContactDialog);

    dialogRef.afterClosed().subscribe(result => {

    });
  }

  logout(){
    localStorage.clear();
    this.login_status = 0;
    this.router.navigate(['/login']);
  }
}


@Component({
  selector: 'pricing-dialog',
  templateUrl: './pricing-dialog.html',
})
export class PricingDialog {}

@Component({
  selector: 'terms-dialog',
  templateUrl: './terms-dialog.html',
})
export class TermsDialog {}

@Component({
  selector: 'privacy-dialog',
  templateUrl: './privacy-dialog.html',
})
export class PrivacyDialog {}

@Component({
  selector: 'cookies-dialog',
  templateUrl: './cookies-dialog.html',
})
export class CookiesDialog {}

@Component({
  selector: 'rodo-dialog',
  templateUrl: './rodo-dialog.html',
})
export class RodoDialog {}

@Component({
  selector: 'contact-dialog',
  templateUrl: './contact-dialog.html',
})
export class ContactDialog {}
