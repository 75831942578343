import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatListModule} from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTabsModule} from '@angular/material/tabs';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PricingDialog } from './app.component';
import { TermsDialog } from './app.component';
import { PrivacyDialog } from './app.component';
import { CookiesDialog } from './app.component';
import { RodoDialog } from './app.component';
import { ContactDialog } from './app.component';
import { HomeComponent } from './home/home.component';
import { CarsComponent } from './cars/cars.component';
import { CarComponent } from './car/car.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { ApiCommunicationService } from './api-communication.service';

import { SlickModule } from 'ngx-slick';
import { PanelComponent } from './panel/panel.component';
import { NgxDropzoneModule } from 'ngx-dropzone';

import { AgmCoreModule } from '@agm/core';
import { CrystalLightboxModule } from '@crystalui/angular-lightbox';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'cars', component: CarsComponent },
  { path: 'cars/:type', component: CarsComponent },
  { path: 'cars/:type/:dealer_id', component: CarsComponent },
  { path: 'car', component: CarComponent },
  { path: 'car/:id', component: CarComponent },
  { path: 'panel', component: PanelComponent },
  { path: 'pricing', component: PricingDialog },
  { path: 'terms', component: TermsDialog },
  { path: 'privacy', component: PrivacyDialog },
  { path: 'announcementdeleteconfirmdialog', component: AnnouncementDeleteConfirmDialog },
  { path: 'cookies', component: CookiesDialog },
  { path: 'rodo', component: RodoDialog },
  { path: 'contact', component: ContactDialog}
];


import { PanelList } from './panel/panel.component';
import { PanelNew } from './panel/panel.component';
import { PanelEdit } from './panel/panel.component';
import { PanelProfile } from './panel/panel.component';
import { PanelSettings } from './panel/panel.component';
import { PanelDealers } from './panel/panel.component';
import { AnnouncementDeleteConfirmDialog } from './panel/panel.component';


@NgModule({
  declarations: [
    AppComponent,
    PricingDialog,
    TermsDialog,
    PrivacyDialog,
    CookiesDialog,
    RodoDialog,
    ContactDialog,
    HomeComponent,
    CarsComponent,
    CarComponent,
    LoginComponent,
    PanelComponent,
    PanelList,
    PanelNew,
    PanelEdit,
    PanelProfile,
    PanelSettings,
    PanelDealers,
    AnnouncementDeleteConfirmDialog
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatToolbarModule,
    MatButtonModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatExpansionModule,
    MatListModule,
    MatDividerModule,
    MatSnackBarModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTabsModule,
    BrowserAnimationsModule,
    NgxDropzoneModule,
    FormsModule,
    ReactiveFormsModule,
    SlickModule.forRoot(),
    RouterModule.forRoot(
      routes
    ),
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBEu2hzCPDfFanRqv_VFeQ2Mg5qijkBMsE'
    }),
    CrystalLightboxModule
  ],
  providers: [
    ApiCommunicationService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
